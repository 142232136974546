section.text {
  h4, h3 {
    font-weight: 300;
  }

  p {
    font-weight: 400;
    font-size: 1rem;
  }

  p.small {
    font-weight: 400;
    font-size: .8rem;
  }

  h5 {
    font-size: 1rem;
    font-weight: 400;
  }

  ol,
  ul {
    p {
      margin-bottom: .2rem
    }
  }

  .margin-top {
    margin-top: 1rem;
  }

  .margin-bottom {
    margin-bottom: 1rem;
  }

}

.bp-content section.text.margin {
    margin: 1rem;
    border-radius: .25rem;
}

.text-blue {
  background: var(--alert-info-background);
}
.text-dark-blue {
  background: var(--navbar-background);
  color: var(--text-white);
}
.text-grey {
  background: var(--panel-background);
}

.highlight-blue {
  color: var(--link-text);
}

.highlight-green {
  color: green;
}

.highlight-yellow {
  color: yellow;
}

.highlight-pink {
  color: deeppink;
}

.highlight-mauve {
  color: darkmagenta;
}