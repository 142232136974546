@import 'colours';

footer.footer {
  background: var(--panel-background);
  border-top: 1px solid #aaa;
  color: var(--text);
  padding: 20px 60px;
  font-size: .8rem;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
}
