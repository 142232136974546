@import '../../../asset/ui/src/scss/colours';
@import '../../../asset/ui/src/scss/breakpoints.scss';

label.title {
  padding-left: .5rem;
  font-size: 1rem;
}

table .btn {
  line-height: 1;
  padding: .25rem;
  min-width: 4rem;
  float: right;
}

table {
  div.alert-info.no-margin {
    margin: -.5rem;
  }
}

div.club-header {
  display: flex;
  justify-content: space-between;
  label {
    font-size: 1.1rem;
    margin: 0;
  }
}

div.club-info {
  grid-template-columns: 20% 78%;
  grid-column-gap: 1rem;
  font-size: .9rem;

  label {
    padding: .25rem .5rem;
    background: var(--alert-info-background);
    color: var(--alert-text);
  }
  span {
    padding: .25rem .5rem;
    background: var(--background);
    margin-bottom: .5rem;
  }

  hr,
  div#club-address-map {
    grid-column: 1/3;
  }
}

div.club-details {
  span.officer {
    padding: .5rem;
    font-size: .9rem;
    grid-template-columns: 25% 72%;
    grid-column-gap: .5rem;
    label {
      padding: .25rem .5rem;
    }
    .officer-label {
      background: var(--alert-info-background);
      color: var(--alert-text);
    }
    .officer-name {
      background: var(--background);
    }
  }

  .officer-details {
    font-size: .9rem;
    margin: .5rem;
    grid-template-columns: 30% 30% 40%;
    grid-column-gap: 1rem;
    .officer-detail-label {
      font-weight: 300;
    }
  }
}

.bp-content section.map {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

a.external {
  margin-left: .25rem;
  color: var(--link-text);
}

.bp-content {
  td.button {
    display: flex;
    justify-content: flex-end;
    button {
      float: none;
    }
  }
}


@media screen and (max-width: $medium) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    display: block;
    margin-bottom: 1rem;
    background: var(--background);
    border-radius: 0.25rem;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 1rem;
    text-align: right;
  }

  table td.button.full {
    display: grid;
  }
  table td.button {
    display: flex;
    //flex-direction: row-reverse;
    button {
      width: auto;
    }
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: 500;
  }

  table td:first-child {
    border-top: 0;
    background: var(--alert-info-background);
  }

  table td.empty:first-child {
    background: var(--background);
  }

  table td:last-child {
    border-bottom: 0;
  }

  table tr.table>td table {
    display: none;
  }
}