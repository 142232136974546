@import "breakpoints";

// Use flex to keep the footer at the bottom.
body,
[data-apos-refreshable],
.bp-wrapper {
  display: flex;
  flex-direction: column;
}

[data-apos-refreshable],
.bp-wrapper,
main {
  flex-grow: 1;
}

body {
  min-height: 100vh;
  // Nice system fonts.
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

main {
  min-height: 75vh;
}

header.apos {
  margin-bottom: 0;
}

.bp-header,
main,
.bp-footer {
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.bp-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding: 40px 0;
}

.bp-footer {
  padding: 40px 0; 
}

.bp-footer__links {
  padding-left: 0px;
  list-style: none;
  text-align: center;

  li {
    display: inline-block;
    margin-right: 20px;
  }
  li:last-child { margin-right: 0; }
}

.bp-header__logo {
  display: block;
  width: 190px;
  max-width: 100%;
  object-fit: contain;
}

.bp-header__login {

  &:visited {
    color: white;
  }
}

.bp-content {
  margin-left: auto;
  margin-right: auto;
  div.apos-area {
    div.bp-rich-text {
      margin: 0 1rem;
    }
  }

  section.align-center {
      display: flex;
      justify-content: center;
  }

  section.margin-top {
    margin-top: 1rem;
  }

  section.margin-bottom {
    margin-bottom: 1rem;
  }
}

label.apos-choice-label {
  display: block;
}


.apos-popover.apos-popover--z-index-in-context {
  z-index: 9999;
}

@media screen and (min-width: $xsmall) {
  .bp-content, body {
    width: 100%
  };
}



@media screen and (max-width: $medium) {
  nav.navbar ul.dropdown-menu {
    border: 0;
  }
}

@media screen and (min-width: $medium) {
  /*body {
    max-width: $medium;
  }
  .bp-content {
    max-width: 600px;
  }*/
  .two-col {
    display: grid;
  }
}

@media screen and (min-width: $large) {
 /* body {
    max-width: $large;
  }
  .bp-content {
    max-width: 800px;
  }*/
}

@media screen and (min-width: $extra) {
  /*body {
    max-width: $extra;
  }
  .bp-content {
    max-width: 1000px;
  }*/
}


.apos-area-widget-wrapper {
  span.apos-button__label {
    text-transform: capitalize;
  }
}

