$danger: #ff8a8a;

$success-color: #33691e;
$success-background: #dcedc8;

$error-color: #bf360c;
$error-background: #ffccbc;

$mid-grey: #888;
$dark-grey: #444;

$pale-blue: #c3ebff;
$lightblue: #F0FAFF;
$blue: #c3ebff;
$info: #E7F5FF;
$darkblue: #344771;
$midblue:#3884d6;
$red:#bf360c;

$dark-background: #3884d6;

$solid: $midblue;

$float-label-color: #212529;
$dark-float-label-color: whitesmoke;

$light-grey: #eee;
$dark-light-grey: #363636;

$lightgreen: #DCEDC8;
$darkgreen: #33691e;
$midgreen: #7CB342;

$bye: #b2dfff;
$walkover: #e1c7fb;
$win: $lightgreen;

$dark-bye: $darkblue;

$bye-background: #3a5eab;
$walkover-background: #9933ff;
$win-background: $darkgreen;

:root,
:root:root {

  --background: #fefefe;
  --text: black;
  --text-black: black;
  --text-white: white;
  --text-darkblue: #{$darkblue};
  --text-red: #{$red};

  --link-text: #{$midblue};
  --menu-hover: initial;

  --file-download-background: #{$darkblue};

  --hr-color: #8c8b8b;

  --navbar-background: #{$darkblue};
  --navbar-text: var(--text-white);
  --navbar-background-focus: whitesmoke;
  --navbar-color-focus: black;
  --navbar-icon: #{$midblue};
  --navbar-highlight: #{$bye};

  --card-background: #fefefe;
  --modal-background: #fefefe;

  --panel-background: #f5f5f5;
  --panel-text: black;

  --alert-info-background: #c3ebff;
  --alert-text: #055160;
  --alert-success-background: #{$lightgreen};
  --alert-success-text: #{$darkgreen};
  --alert-danger-background: #{$error-background};
  --alert-danger-text: #{$error-color};

  --table-color: #212529;

  --input-background: white;
  --input-background-focus: white;
  --select-control: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");;

  --floating-label: #{$float-label-color};

  --vertical-pill-background: #fefefe;
  --vertical-pill-border: #3884d6;
  --vertical-pill-background-active: #{$darkblue};
  --vertical-pill-background-hover: #{$lightblue};
  --vertical-pill-text-hover: #{$darkblue};

  --tab-border: rgb(222, 226, 230);

  --comp-empty-background: #{$light-grey};
  --comp-win-background: #{$lightgreen};
  --comp-pending-background: #{$lightblue};
  --comp-walkover-background: #{$walkover};
  --comp-bye-background: #{$bye};

  --comp-empty-color: #{$light-grey};
  --comp-win-color: #{$win-background};
  --comp-pending-color: #{$lightblue};
  --comp-walkover-color: #{$walkover-background};
  --comp-bye-color: #{$bye-background};

}


@media (prefers-color-scheme: dark) {
  :root,
  :root:root {
    --background: #222;
    --bs-body-bg: black;
    --text: #d3d6d9;
    --link-text: #3884d6;
    --menu-hover: #ffffff25;

    --hr-color: #aaa;

    --navbar-background: #{$darkblue};
    --navbar-text: whitesmoke;
    --navbar-background-focus: black;
    --navbar-color-focus: #{$lightblue};
    --navbar-icon: #{$lightblue};

    --card-background: #444;
    --modal-background: #606060;

    --panel-background: #444;
    --panel-text: #c9d1d9;

    --alert-info-background: #{$darkblue};
    --alert-text: rgba(255,255,255,.75);
    --alert-danger-background: #{$error-color};
    --alert-danger-text: #{$error-background};
    --alert-success-background: #{$darkgreen};
    --alert-success-text: whitesmoke;

    --table-color: #d3d6d9;

    --input-background: #333;
    --input-background-focus: #222;
    --select-control: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");

    --floating-label: #{$dark-float-label-color};

    --vertical-pill-background: #222;
    --vertical-pill-border: #444;
    --vertical-pill-background-active: whitesmoke;
    --vertical-pill-background-hover: #{$darkblue};
    --vertical-pill-text-hover: whitesmoke;

    --tab-border: #747474;

    --comp-empty-background: #{$dark-light-grey};
    --comp-win-background: #{$darkgreen};
    --comp-pending-background: #{$dark-background};
    --comp-walkover-background: #{$walkover};
    --comp-bye-background: #{$dark-bye};

    --comp-empty-color: #{$light-grey};
    --comp-win-color: #{$win};
    --comp-pending-color: #{$lightblue};
    --comp-walkover-color: #{$walkover-background};
    --comp-bye-color: #{$pale-blue};
  }
}
