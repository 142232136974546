button.document-download {
  padding: 0;
  display: flex;
  justify-content: space-between;
  label {
    padding: 0.3rem 1rem;
  }
  label {
    a {
      text-decoration: none;
      color: white;
    }
    a:active {
      color: var(--file-download-background);
    }
  }
  span {
    padding: .3rem .5rem;
    background: var(--file-download-background);
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    :active {
      color: white;
    }
  }
}

button.document-download:active span {
  color: white;
}

div.col {
  button {
    width: 100%;
  }
}