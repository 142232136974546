@import "colours";

div.card-body>div.alert-info,
div.card-body>div.alert-success,
div.card-body>div.alert-danger {
  margin-top: -1rem;
}

.card {
  background: var(--card-background);
  color: var(--panel-text);
}

.card-header {
  background: var(--navbar-background);
}

button.btn.btn-primary {
  background-color: $solid;
  border-color: $solid;
  transition: background-color .25s;
}

button.btn-primary:hover {
  background-color: $darkblue;
  border-color: $darkblue;
  color: white;
}

button + button  {
  margin-right: .25rem;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle,
button.btn-primary:active {
  background: white;
  color: $darkblue;
}

button.btn.btn-secondary {
  background: var(--background);
  color: var(--text);
}

button.btn-secondary:hover {
  background: $darkblue;
  border-color: $darkblue;
  color: white;
}


.nav-tabs .nav-link {
  border: 1px solid #dee2e6;
}

.nav-tabs .nav-link:hover {
  background: var(--panel-background);
}

.nav-tabs .nav-link {
  color: var(--link-text);
  border: 1px solid var(--tab-border);
}

.nav-tabs .nav-link.active {
  background-color: var(--panel-background);
  border-bottom-color: var(--panel-background);
  color: var(--panel-text);
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--panel-text);
  background-color: var(--panel-background);
  border-top-color: var(--tab-border);
  border-right-color: var(--tab-border);
  border-bottom-color: var(--panel-background);
  border-left-color: var(--tab-border);
}

ul.nav-pills .nav-item:hover,
ul.nav-pills .nav-item a:hover {
  background: var(--vertical-pill-background-hover);
  color: var(--vertical-pill-text-hover);
}

ul.nav-pills .nav-item:active,
ul.nav-pills .nav-item a:active {
  background: var(--vertical-pill-background-active);
  color: var(--background);
}

div.side-menu {
  ul li a {
    font-size: .9rem;
  }
}

ul.nav-pills li a.nav-link.active,  {
  background: $solid;
}

div.container div.body div.side-menu .nav-pills li.nav-item {
  background: var(--vertical-pill-background);
  border: 1px solid;
  border-color: var(--vertical-pill-border);
  border-radius: .25rem;
}

div.alert-info,
div.alert-success,
div.alert-warning,
div.alert-danger {
  padding: 1rem;
  margin-bottom: 0;
}

div.alert-info {
  background: var(--alert-info-background);
  border-color: var(--alert-info-background);
  color: var(--alert-text);
}

div.alert-success {
  background: var(--alert-success-background);
  color: var(--alert-success-text);
}

div.alert-danger {
  background: var(--alert-danger-background);
  color: var(--alert-danger-text);
}

div.modal-header {
  background: var(--panel-background);
}

.mat-datepicker-content {
  background: var(--background);
  color: var(--text);
}

.mat-calendar-table-header th,
.mat-calendar-body-label,
.mat-calendar-body-cell-content,
.mat-datepicker-content .mat-calendar-previous-button,
.mat-datepicker-content .mat-calendar-next-button {
  color: var(--text);
}

.mat-calendar-arrow {
  border-top-color: var(--text);
}

.mat-calendar-table-header-divider::after {
  background: var(--text);
}

.mat-datepicker-toggle-default-icon {
  fill: var(--text);
  position: absolute;
  z-index: 9999;
  bottom: 0.15rem;
  right: 0.25rem;
  margin-bottom: 0.25rem;
}

ul.nav {
  display: flex;
  flex-wrap: wrap-reverse;
}

.modal {
  .modal-header {
    color: var(--text);
  }
  .modal-content {
    background-color: var(--modal-background);
  }
}

