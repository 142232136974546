i.fa-solid
  .black {
    color: var(text-black);
  }
  .darkblue {
    color: var(--text-darkblue);
  }
  .red {
    color: var(--text-red)
  }

  .small {
    font-size: .9rem;
  }
  .medium {
    font-size: 1.5rem;
  }
  .large {
    font-size: 3rem;
  }
  .x-large {
    font-size: 5rem;
  }
