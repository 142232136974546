@import 'theme_mixins';

:root,
:root:root,
.apos-theme-light {
  --a-danger: #eb443b;
  --a-danger-fade: #eb443b30;
  --a-success: #00bf9a;
  --a-success-fade: #00bf9a30;
  --a-warning: #ffce00;
  --a-warning-fade: #ffce0030;
  --a-progress-bg: #2c354d;

  --a-danger-button-hover: #c00717;
  --a-danger-button-active: #a10000;
  --a-danger-button-disabled: #ff9d98;

  --a-base-1: #5d5d5d;
  --a-base-2: #6f6f6f;
  --a-base-3: #818181;
  --a-base-4: #939393;
  --a-base-5: #a5a5a5;
  --a-base-6: #b7b7b7;
  --a-base-7: #c9c9c9;
  --a-base-8: #dbdbdb;
  --a-base-9: #ededed;
  --a-base-10: #f4f4f4;

  --a-text-primary: #000;
  --a-text-inverted: #fff;

  // Borders
  --a-border-radius: 5px;
  --a-box-shadow: 0 3px 13px 4px rgba(0, 0, 0, 0.08);

  // Backgrounds
  --a-background-primary: #fff;
  --a-background-inverted: #323232;
  --a-overlay: #1f004ce0;
  --a-overlay-modal: #0202025e;

  // Universal
  --a-white: #fff;
  --a-black: #000;

  // Apostrophe brand
  --a-brand-red: #{$brand-red};
  --a-brand-gold: #{$brand-gold};
  --a-brand-magenta: #{$brand-magenta};
  --a-brand-blue: #{$brand-blue};
  --a-brand-green: #{$brand-green};

  // File colors
  --a-presentation: #f1c40f;
  --a-document: #3498db;
  --a-generic: var(--a-base-1);
  --a-spreadsheet: #2ecc71;
  --a-pdf: #e74c3c;

  // Font-Family
  --a-family-default: Lato, "Helvetica Neue", sans-serif;

  // Font-Weights
  --a-weight-base: 500;
  --a-weight-bold: 700;

  // Type Sizes
  --a-type-tiny: 9px;
  --a-type-small: 10px;
  --a-type-base: 12px;
  --a-type-label: 13px;
  --a-type-large: 14px;
  --a-type-heading: 22px;
  --a-type-display: 32px;

  // Letterspacing
  --a-letter-base: 0.5px;
  --a-letter-wide: 1px;

  // LineHeight
  --a-line-base: unset;
  --a-line-tall: 1.3;
  --a-line-tallest: 1.6;

  // Admin styles
  --a-widget-margin: 0;
}

/*
@media (prefers-color-scheme: dark) {
  :root,
  :root:root,
  .apos-theme-dark {
    .apos-theme--primary-blue {
      @include apos-primary-mixin(#0062ff);
    }

    --a-base-1: #d1d3d5;
    --a-base-2: #6b7c92;
    --a-base-3: #607084;
    --a-base-4: #566477;
    --a-base-5: #4b5869;
    --a-base-6: #414d5b;
    --a-base-7: #36414d;
    --a-base-8: #2c3540;
    --a-base-9: #212932;
    --a-base-10: #171d24;

    --a-background-primary: #0b0b0c;
    --a-background-inverted: #fff;
    --a-overlay: #0062ffe0;

    --a-text-primary: #fff;
    --a-text-inverted: #000;
  }

}
*/


.apos-theme--primary-default {
  @include apos-primary-mixin(#6516dd);
}
.apos-theme--primary-blue {
  @include apos-primary-mixin(#0062ff);
}
.apos-theme--primary-orange {
  @include apos-primary-mixin(#e65100);
}
.apos-theme--primary-aqua {
  @include apos-primary-mixin(#1eacc7);
}
.apos-theme--primary-sun {
  @include apos-primary-mixin(#f7a704);
}
.apos-theme--primary-green {
  @include apos-primary-mixin(#00b075);
}
.apos-theme--primary-pink {
  @include apos-primary-mixin(#fd9ec0);
}
