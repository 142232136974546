section .page-link,
section.page-link-text,
section.page-link-button {
  display: inline-block;
  background: none;
}

section.page-link-text.d-inline-block+section.page-link-button.d-inline-block a,
section.page-link-button.d-inline-block+section.page-link-button.d-inline-block a {
  //padding-left: 0;
}

section.page-link-button span {
  margin-right: .25rem;
}

section .page-link:hover {
  background: none;
  text-decoration: underline;
}

section.page-link-text a,
section.page-link-text a span {
  text-decoration: underline;
  padding: .375rem 0;
}

section span+a.page-link  {
  padding-left: 0;
}

section.background-dark-blue section .page-link {
  color: white;
}

section.page-link-button.full-width {
  width: 100%;
  a,
  button {
    width: 100%;
  }
}
