@import '../../../asset/ui/src/scss/breakpoints';

.two-col {
  display: grid;
  flex-flow: row wrap;
  width: 100%;
}

div.two-col__column {
  display: flex;
  flex-direction: column;
  div.apos-area {
    display: flex;
    flex-direction: column;
    flex: 1;
    section {
      flex: 1
    }
  }
}

.two-col__column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.two-col__column.vertical-top {
  justify-content: start;
}

.two-col__column.vertical-center {
  justify-content: center;
}

.two-col__column.vertical-bottom {
  justify-content: end;
}

@media screen and (max-width: $small) {
  .two-col {
    display: block;
    .two-col__column {
      margin-bottom: 1rem;
    }
  }
}