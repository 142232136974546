@import "breakpoints.scss";
@import "colours.scss";

nav.navbar div.container-fluid {
  min-height: unset;
  margin-top: 0;

}

nav.navbar {
  background: var(--navbar-background);
  color: var(--navbar-text);
  //border-bottom: 1px solid var(--navbar-background);
  padding-top: 0;
  padding-bottom: 0;
  font-size: .9rem;
  font-weight: 300;
  .mat-menu-item,
  .navbar-brand {
    font-size: .9rem;
    font-weight: 300;
    padding: 1rem .5rem;
    color: var(--navbar-text);
  }

  ul.navbar-nav {
    --bs-nav-link-padding-y: 0.6rem;
    align-items: center;
    margin-left: .5rem;
  }

  ul.menu-items li {
    min-width: 5rem;
    display: list-item;
    justify-content: center;
  }
  ul.menu-items li.home {
    min-width: unset;
    padding: 0;
    a {
      padding: 0;
      cursor: pointer;
    }
    a:hover {
      background: unset;
    }
  }

  img.brand {
    margin-left: .5rem;
    margin-right: .5rem;
    width: 2rem;
  }

  li.nav-item {
    padding: 1rem 0;
  }

  li.nav-item.home {
    padding: 0;
  }

  li.nav-item:hover {
    background: var(--link-text);
  }

  li.nav-item.home:hover {
    background: none;
  }

  .icon {
    color: var(--navbar-icon);
  }

  .icon:hover {
    cursor: pointer;
    color: var(--navbar-text);
  }

  li.home a:hover {
    background: unset;
  }

  .dropdown-menu {
    background: var(--navbar-background);
    color: var(--navbar-text);
    font-size: .9rem;
    a {
      font-weight: 300;
    }
    margin-top: -.25rem;
  }

  .dropdown-menu li a:hover {
    background: var(--link-text);
    color: white;
  }

  i:hover,
  a.nav-link:hover,
  a.menu-item:hover,
  a.dropdown-toggle:hover {
    color: var(--text-white);
  }

  a {
    color: var(--navbar-text);
  }

  a.nav-link,
  a.dropdown-toggle {
    display: flex;
    justify-content: center;
  }
}

.navbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

a.dropdown-toggle::after {
  display: none;
}

.navbar-toggler {
  color: var(--text);
  border-color: rgba(0, 0, 0, 0.1);
}

.sidenav-container {
  height: 100%;
}

.sidenav {
  width: 200px;
}

.sidenav .mat-toolbar {
  background: inherit;
}

.mat-toolbar {
  justify-content: space-between;

  div.menu-item {
    display: flex;
  }
  .details {
    margin-top: -.1rem;
  }
}

.mat-toolbar.mat-primary {
  position: sticky;
  top: 0;
  z-index: 1;
}



div.menu {
  display: flex;
  background: var(--navbar-background);

  div.profile {
    display: flex;
    align-items: center;
  }
}


@media screen and (max-width: $medium) {
  nav.navbar {
    a.navbar-brand,
    a.nav-link {
      padding-left: 0;
    }

    li.nav-item {
      padding: 0;
      width: 100%;
    }

    img.brand {
      margin: 0;
    }

    a.navbar-brand:hover,
    li.nav-item:hover {
      background: initial;
    }

    a.dropdown-item {
      display: flex;
      justify-content: center;
    }
  }
  nav .dropdown-item {
    margin-left: 0;
  }
  nav ul {
    border: none;
  }

}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding: 0
  }
}