.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    margin-right: 1rem;
    font-size: 1.5rem;
  }
  span {
    font-size: .85rem;
  }
}