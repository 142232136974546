.background-blue {
  background: var(--alert-info-background);
  color: var(--text-black)
}
.background-grey {
  background: var(--panel-background);
  color: var(--text-black);
}
.background-dark-blue {
  background: var(--navbar-background);
  color: var(--text-white);
}

.bp-content section.margin,
.margin {
  margin-left: 1rem;
  margin-right: 1rem;
}

.bp-content section.container-widget.inner-margin {
  padding: 1rem;
}

.margin-top {
  margin-top: 1rem;
}
.margin-bottom {
  margin-bottom: 1rem;
}

.container-widget.margin {
  border-top: rgba(0,0,0,0) 1px solid;
  border-bottom: rgba(0,0,0,0) 1px solid;
}