.image-link {
  max-width: 100%;
  img.image-link {
    transition-property: filter;
    transition-duration: .5s;
  }
    div {
      position: relative;
      span {
        position: absolute;
        left: .5rem;
        bottom: 1rem;
        color: white;
        font-size: 1.5rem;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity .5s linear;
      }
    }
}

section.section-image.padding {
  padding: 1rem;
}

a.image-link:hover {
  img.image-link:hover {
    filter: brightness(50%);
  }
  span {
    display: block;
    transform: translate(1rem, 0);
    visibility: visible;
    opacity: 1;
  }
}

