.bp-content section {
  margin: 0;
}

.bp-video-widget {
  width: 100%;
}

.bp-image-widget {
  max-width: 100%;
}

section[data-rest] {
  font-size: 1rem;
}