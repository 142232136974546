@import "modules/asset/ui/src/scss/breakpoints";

.widget-table {
  display: flex;
  //flex-wrap: wrap;

}

.widget-table-1,
.widget-table-2,
.widget-table-3,
.widget-table-4,
.widget-table-5 {
  display: flex;
}

.widget-table-1 {

}

div.widget-table.stretch {
  justify-content: space-evenly;

  div.col {
    flex: 1;
    display: flex;
    flex-direction: column;
    div.apos-area {
      display: flex;
      flex-direction: column;
      flex: 1;
      section {
        flex: 1;
      }
    }
  }
}

@media screen and (max-width: $small) {
  .widget-table-1,
  .widget-table-2,
  .widget-table-3,
  .widget-table-4,
  .widget-table-5 {
    display: block;

    div.col {
      margin-bottom: 1rem;
    }
  }
}

@media screen and (max-width: $small) {
  .widget-table-4,
  .widget-table-5 {
    display: block;
    div.col {
      margin-bottom: 1rem;
    }
  }

}


