span.highlight {
  color: red;
}

section.text {
  padding: .5rem 1rem;

}

section.text.boxed {
  >div {
    margin-bottom: -1rem;
  }
}



