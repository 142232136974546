@import url('~normalize.css');
// NOTE: We're using a `.bp-` namespace to indicate these are the boilerplate
// styles.
@import './scss/theme';
@import './scss/_settings';
@import './scss/_type';
@import './scss/_layout';
@import './scss/_welcome';
@import './scss/_widgets';
@import './scss/bootstrap';
@import './scss/component';
@import './scss/navbar';
@import './scss/footer';
@import './scss/colours';
@import './scss/text';
@import '/node_modules/@glidejs/glide/dist/css/glide.theme.min.css';

body,
main {
  background: var(--background);
  max-width: unset;
  margin: 0;
  font-family: 'Open Sans', 'SansSerif', sans-serif;
  font-size: .8rem;
  --a-widget-margin: 0;
  p {
    font-size: 1rem;
  }
}

body {
  main {
    color: var(--text);
  }
}

main {
  padding-bottom: 2rem;
}

nav {
  a,
  a:hover,
  a:focus {
    text-decoration: none;
  }
}

div.col button {
  width: unset;
}